import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'components/form';
import PageSpinner from 'components/page-spinner';
import TextInput from 'components/text-input/text-input';
import HiddenInput from 'components/hidden-input';
import AppointmentBookingAgreeBox from 'components/appointment-booking-modal/appointment-booking-agree-box';

const AppointmentBookingForm = ({
  form,
  appointmentBookingUserName,
  appointmentBookingBirthDate,
  appointmentBookingEmail,
  appointmentBookingPhoneNumber,
  appointmentBookingAddInfo,
  appointmentBookingAttentionText,
  onComplete,
  setAssistantChosenOnBE,
  setReservedBooking,
  reservedBookingId,
  shopId,
  appointmentId,
  assistantId,
  activeDate,
  timeSlot,
  agreeBoxData,
  onClickAgree,
  conditionalTextAppointmentType,
  conditionalBookingUserNameText
}) => {
  const [isFormSent, setIsFormSent] = useState(false);
  const [reservedDate, setReservedDate] = useState('');
  const [reservedTime, setReservedTime] = useState('');
  const [reservedShop, setReservedShop] = useState('');
  const [reservedOpticianId, setReservedOpticianId] = useState('');
  const [reservedAppType, setReservedAppType] = useState('');
  const [useReservedBooking, setUseReservedBooking] = useState('');

  const conditionalUserNameText =
    conditionalTextAppointmentType &&
    appointmentId === conditionalTextAppointmentType
      ? conditionalBookingUserNameText
      : null;

  useEffect(() => {
    // runs when form sent successfully
    if (isFormSent) onComplete();
  }, [isFormSent]);

  useEffect(() => {
    setUseReservedBooking(
      reservedDate === activeDate &&
        reservedTime === timeSlot &&
        reservedShop.toString() === shopId &&
        (assistantId ? reservedOpticianId === assistantId : true) &&
        reservedAppType === appointmentId
    );
  });

  return (
    <Form
      className="appointment-booking-form"
      showSubmitButton={true}
      onResponse={({
        opticianId,
        complete,
        appointmentId,
        date,
        time,
        appType,
        shop
      }) => {
        // set assistant from BE only in case if user did not set it before
        complete && !assistantId && setAssistantChosenOnBE(opticianId);
        complete && setIsFormSent(true);

        if (complete) {
          window.__rpta = window.__rpta || [];
          window.__rpta.push('event', 'Bestilt syntest');
        }

        if (!complete && appointmentId) {
          setReservedBooking(appointmentId);
          setReservedDate(date);
          setReservedTime(time);
          setReservedShop(shop);
          setReservedOpticianId(opticianId);
          setReservedAppType(appType);
          setUseReservedBooking(true);
          setAssistantChosenOnBE(opticianId);
        }
      }}
      {...form}
    >
      {({ isLoading }) => (
        <React.Fragment>
          <PageSpinner shouldContainInParent={true} isLoading={isLoading} />
          <HiddenInput name="shopId" value={shopId} />
          <HiddenInput name="appointmentId" value={appointmentId} />
          <HiddenInput name="opticianId" value={assistantId} />
          <HiddenInput name="activeDate" value={activeDate} />
          <HiddenInput name="timeSlot" value={timeSlot} />
          {useReservedBooking && (
            <HiddenInput
              name="reservedAppointmentId"
              value={reservedBookingId}
            />
          )}
          <TextInput
            validations={['isUserName']}
            autocomplete="name"
            labelConditionalText={conditionalUserNameText}
            {...appointmentBookingUserName}
          />
          <TextInput
            validations={['isRequired', 'isBirthDate']}
            {...appointmentBookingBirthDate}
          />
          <TextInput
            validations={['isRequired', 'isEmail']}
            type="email"
            autocomplete="email"
            {...appointmentBookingEmail}
          />
          <TextInput
            validations={['isRequired', 'isPhoneNumber']}
            autocomplete="tel"
            {...appointmentBookingPhoneNumber}
          />
          <span className="appointment-booking-form__text-attention">
            {appointmentBookingAttentionText}
          </span>
          <TextInput {...appointmentBookingAddInfo} />
          <AppointmentBookingAgreeBox
            className="left-aligned"
            onClickAgree={onClickAgree}
            {...agreeBoxData}
          />
        </React.Fragment>
      )}
    </Form>
  );
};

AppointmentBookingForm.propTypes = {
  form: PropTypes.exact(Form.propTypes),
  appointmentBookingUserName: PropTypes.exact(TextInput.propTypes),
  conditionalTextAppointmentType: PropTypes.string,
  conditionalBookingUserNameText: PropTypes.string,
  appointmentBookingBirthDate: PropTypes.exact(TextInput.propTypes),
  appointmentBookingEmail: PropTypes.exact(TextInput.propTypes),
  appointmentBookingPhoneNumber: PropTypes.exact(TextInput.propTypes),
  appointmentBookingAttentionText: PropTypes.string,
  appointmentBookingAddInfo: PropTypes.exact(TextInput.propTypes),
  onComplete: PropTypes.func,
  setAssistantChosenOnBE: PropTypes.func,
  setReservedBooking: PropTypes.func,
  reservedBookingId: PropTypes.string,
  shopId: PropTypes.string,
  appointmentId: PropTypes.string,
  assistantId: PropTypes.string,
  activeDate: PropTypes.string,
  timeSlot: PropTypes.string,
  agreeBoxData: PropTypes.shape({
    label: PropTypes.string,
    infoTitle: PropTypes.string,
    infoDescription: PropTypes.string,
    name: PropTypes.string
  }),
  onClickAgree: PropTypes.func
};

AppointmentBookingForm.propTypesMeta = {
  shopId: 'exclude',
  appointmentId: 'exclude',
  assistantId: 'exclude',
  activeDate: 'exclude',
  timeSlot: 'exclude',
  conditionalTextAppointmentType: 'exclude',
  conditionalBookingUserNameText: 'exclude'
};

AppointmentBookingForm.defaultProps = {
  onComplete: () => {}
};

export default AppointmentBookingForm;
